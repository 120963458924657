<template>
  <div class="customer_container">
    <el-container>
      <el-main>
        <!-- 头部 -->
        <PageHeader></PageHeader>
        <!-- 中间客服二维码部分 -->
        <div class="service_box">
          <div class="details">
            <div class="title_top">
              <div class="title">联系客服</div>
              <div class="icon" @click="closeToIndex"><i class="el-icon-close"></i></div>
            </div>
            <!-- 二维码 -->
            <div class="code_line">
              <div class="label">微信客服:</div>
              <div class="photo_box">
                <div class="pc_code">
                  <div style="height:30px;line-height:30px">PC客服端</div>
                  <img :src="code_pic" alt="">
                </div>
                <div class="mobile_code">
                  <div style="height:30px;line-height:30px">移动端客服端</div>
                  <img :src="code_pic" alt="">
                </div>
              </div>
            </div>
            <!-- 联系号码 -->
            <div class="contactWay">
              <div class="label">电话客服:</div>
              <div class="phone_num">
                <div class="pc_phone">
                  <div style="height:30px;line-height:30px">0769-82762716</div>
                </div>
                <div class="mobile_phone">
                  <div style="height:30px;line-height:30px">0769-82762716</div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </el-main>
      <!-- 底部 -->
      <el-footer>
        <BottomFooter></BottomFooter>
      </el-footer>
    </el-container>

  </div>
</template>

<script>
  import BottomFooter from '../components/BottomFooter'
  import PageHeader from '../components/PageHeader'

  export default {
    name: 'WorkspaceJsonLicense',
    components: {
      BottomFooter,
      PageHeader
    },

    data() {
      return {
        license_pic: require('../assets/license.jpg'),
        code_pic: require('../assets/code.png')

      };
    },

    mounted() {

    },

    methods: {
      closeToIndex() {
        this.$router.push('/index')
      }

    },
  };

</script>

<style lang="scss" scoped>
  .customer_container {
    background: url(".././assets/bgc.png") no-repeat center center / auto 100% rgb(245, 245, 245);
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    min-width: 960px;
    position: fixed;

    .el-container {
      height: 100%;

      .el-main {
        height: calc(100vh -100px);


        .service_box {
          width: 100%;
          height: 500px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;

          .details {
            width: 700px;
            height: 400px;
            border-radius: 10px;
            background-color: #fff;
            box-sizing: border-box;
            padding: 0 60px 20px 50px;

            .title_top {
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              margin-bottom: 40px;

              .title {
                font-size: 20px;
                font-weight: bold;
                margin-top: 12px;
              }

              .icon {
                position: absolute;
                top: 10px;
                right: -45px;
                font-size: 25px;
                cursor: pointer;
              }
            }

            .code_line {
              width: 100%;
              display: flex;
              align-items: center;

              .label {
                width: 150px;
                text-align: left;
              }

              .photo_box {
                width: 700px;
                display: flex;
                justify-content: space-between;

                .pc_code,
                .mobile_code {
                  width: 180px;
                  height: 180px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }

            .contactWay {
              width: 100%;
              display: flex;
              align-items: center;
              margin-top: 50px;

              .label {
                width: 150px;
                text-align: left;
              }

              .phone_num {
                width: 700px;
                display: flex;
                justify-content: space-between;

                .pc_phone,
                .mobile_phone {
                  width: 180px;
                }
              }
            }
          }


        }
      }

      .el-footer {
        height: 100px !important;
        background-color: #fff;

        .index_footer {
          width: 90%;
          height: 100%;
          margin: 0px auto;
          display: flex;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left_box {
            width: 350px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            .left_item {
              width: 90px;
              cursor: pointer;
              margin-bottom: 6px;
            }

          }
        }
      }
    }
  }

</style>
